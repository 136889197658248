export const useErrorModal = () => {
  interface ErrorModalMessage {
    title: string;
    message: string;
  }
  const showModal = useState<boolean>("showErrorModal", () => false);
  const modalMessage = useState<ErrorModalMessage>("modalErrorMessage", () => {
    return { title: "", message: "" };
  });

  const openModal = (message: ErrorModalMessage) => {
    modalMessage.value = message;
    showModal.value = true;
  };

  const closeModal = () => {
    clearError();
    showModal.value = false;
    modalMessage.value = { title: "", message: "" };
    location.reload();
  };

  return { showModal, modalMessage, openModal, closeModal };
};
