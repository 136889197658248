import validate from "/builds/go7seas/website/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45global from "/builds/go7seas/website/middleware/01.redirect.global.ts";
import auth_45global from "/builds/go7seas/website/middleware/auth.global.ts";
import dia_45show_45global from "/builds/go7seas/website/middleware/diaShow.global.ts";
import manifest_45route_45rule from "/builds/go7seas/website/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45global,
  auth_45global,
  dia_45show_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "step-guard": () => import("/builds/go7seas/website/middleware/stepGuard.ts")
}