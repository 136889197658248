import hash from "object-hash";
import type { UseFetchOptions } from "~/node_modules/nuxt/app";

export const useGo7seasApi = <T>(
  url: string | (() => string),
  options?: UseFetchOptions<T>
) => {
  const key = hash({
    url,
    options,
  });

  const mergedOptions = {
    ...options,
    ...{
      retry: options?.method === undefined ? 3 : 0,
      retryStatusCodes: [0, 408, 499, 444, 504],
      headers: defaultApiHeaders(),
      key,
    },
  };

  const { $api } = useNuxtApp();

  return useFetch(url, {
    ...mergedOptions,
    $fetch: $api as typeof $fetch,
  });
};

export const useGo7seasClient = <T>(
  url: string,
  options?: UseFetchOptions<T>
) => {
  const mergedOptions = {
    ...options,
    ...{
      retry: options?.method === undefined ? 3 : 0,
      retryStatusCodes: [0, 408, 499, 444, 504],
      headers: defaultApiHeaders(),
    },
  };

  const { $api } = useNuxtApp();

  return $api<T>(url, mergedOptions);
};

export function defaultApiHeaders(): HeadersInit {
  const auth = useAuth();
  const { $tabToken } = useNuxtApp();
  const header: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (import.meta.client) {
    header["X-Tab-Token"] = $tabToken as string;
  }
  if (auth.check) {
    header["X-Auth-Token"] = auth.get();
  }

  return header;
}
