export const useLoading = (loading: boolean | null = null): Ref<boolean> => {
  const state = useState("loading", () => false);
  if (loading !== null) {
    state.value = loading;
  }
  return state;
};

const apiLoading = ref(false);

export const useApiLoading = (loading: boolean | null = null): Ref<boolean> => {
  if (loading !== null) {
    apiLoading.value = loading;
  }
  return apiLoading;
};
