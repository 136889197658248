export default defineNuxtPlugin(() => {
  const auth = useAuth();
  const api = $fetch.create({
    baseURL: useRuntimeConfig().public.apiUrl,
    timeout: 40000,
    onRequest() {
      useApiLoading(true);
    },
    async onResponseError({ response }) {
      useApiLoading(false);
      const error = response._data;

      if (error.type === "https://go7seas.com/problem/business-constraint") {
        clearError();
        await navigateTo("/angebot-anfrage", {
          replace: true,
        });
      }

      if (error.type === "https://go7seas.com/problem/redirect-to-page") {
        const { openModal } = useErrorModal();
        if (error.title) {
          openModal({ title: error.title, message: "" });
        }
        await nextTick();
        await navigateTo(error.redirect ?? "/", {
          replace: true,
        });
      }

      if ([404, 500, 501, 503].includes(error.status)) {
        throw createError({
          statusCode: error?.detail?.includes("PageType") ? 404 : error.status,
          statusMessage: error.message ?? response.statusText,
          fatal: true,
        });
      }
    },
    onResponse({ response }) {
      useApiLoading(false);
      if (import.meta.client) auth.refresh();
      const headers = response.headers;
      const token = headers.get("x-auth-token");
      if (token !== null) {
        auth.set(token);
      }
    },
  });

  return {
    provide: {
      api: api as <T>(url: string, options?) => Promise<T>,
    },
  };
});
