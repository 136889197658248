interface AuthState {
  user: boolean;
  expiresAt: number | null;
  token: string | null;
}

export const useAuth = () => {
  const cookie = useCookie<AuthState>("auth-state", {
    default: defaults,
    maxAge: 60 * 60 * 24 * 5000,
  });
  function defaults(): AuthState {
    return {
      user: false,
      expiresAt: null,
      token: null,
    };
  }

  function reset() {
    cookie.value = defaults();
  }

  const valid: ComputedRef<boolean> = computed(() => {
    return cookie.value.token !== null;
  });

  const set = (t: string) => {
    cookie.value.token = t;
    refresh();
  };

  const setUser = () => {
    if (valid.value) {
      cookie.value.user = true;
      refresh();
    }
  };

  const get = (): string => {
    // if session has expired we do not give the old token
    if (cookie.value.expiresAt !== null) {
      if (new Date(cookie.value.expiresAt) <= new Date()) {
        cookie.value = defaults();
        return "";
      }
    }

    return cookie.value.token ?? "";
  };

  const logout = (data?: Record<string, string>): void => {
    reset();
    navigateTo({
      path: "/einloggen",
      query: data ?? {
        msg: "ausgeloggt",
      },
    });
  };

  const login = async (userName: string, password: string): Promise<void> => {
    await useGo7seasClient("/catalog/mygo7seas/login", {
      method: "POST",
      body: {
        user_name: userName,
        password,
      },
    });
  };

  const refresh = () => {
    cookie.value.expiresAt = whenSessionExpires();
  };

  function whenSessionExpires(): number {
    const currentDate = new Date();
    return currentDate.setMinutes(currentDate.getMinutes() + 20);
  }

  const check = computed((): boolean => cookie.value.user === true);
  const guest = computed((): boolean => cookie.value.user === false);

  return {
    reset,
    valid,
    set,
    get,
    refresh,
    logout,
    check,
    guest,
    login,
    setUser,
  };
};
